import * as React from 'react'

interface ConcurrentConnectionsCaptionProps {}

const ConcurrentConnectionsCaption: React.FunctionComponent<
  ConcurrentConnectionsCaptionProps
> = () => (
  <figcaption>
    Info taken from{' '}
    <a
      href="http://www.browserscope.org/?category=network&v=top"
      target="_blank"
      rel="noreferrer noopener"
    >
      Browserscope
    </a>
  </figcaption>
)

export default ConcurrentConnectionsCaption
