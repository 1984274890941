import { graphql } from 'gatsby';
import BannerImage from "../../../../src/posts/the-80-20-of-flexbox/images/banner.png";
import FlexboxDefaultLayoutImage from "../../../../src/posts/the-80-20-of-flexbox/images/flexbox-default-layout.png";
import JustifyContentImage from "../../../../src/posts/the-80-20-of-flexbox/images/justify-content.png";
import AlignItemsImage from "../../../../src/posts/the-80-20-of-flexbox/images/align-items.png";
import FlexWrapImage from "../../../../src/posts/the-80-20-of-flexbox/images/flex-wrap.png";
import FlexShrinkImage from "../../../../src/posts/the-80-20-of-flexbox/images/flex-shrink.png";
import FlexGrowImage from "../../../../src/posts/the-80-20-of-flexbox/images/flex-grow.png";
import FlexBasisImage from "../../../../src/posts/the-80-20-of-flexbox/images/flex-basis.png";
import React from 'react';
export default {
  graphql,
  BannerImage,
  FlexboxDefaultLayoutImage,
  JustifyContentImage,
  AlignItemsImage,
  FlexWrapImage,
  FlexShrinkImage,
  FlexGrowImage,
  FlexBasisImage,
  React
};