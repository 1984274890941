import * as React from 'react'

type YoutubeVideoProps = {
  id: string
  title: string
  start?: string | number
}

const YoutubeVideo = (props: YoutubeVideoProps) => (
  <iframe
    src={`https://www.youtube.com/embed/${props.id}?start=${props.start || 0}`}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title={props.title || ''}
  />
)

export default YoutubeVideo
